import * as React from "react"
import { graphql } from "gatsby"

/* import Bio from "../components/bio" */
import Layout from "../components/layout"
import Seo from "../components/seo"

import '../styles/Post.css'
import "katex/dist/katex.min.css"
import { Helmet } from "react-helmet"

import label_light from '../images/icons/label-light.svg'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  /* const { previous, next } = data */

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Helmet>
        <meta property="og:image" content={post.frontmatter.image_url} />
        <meta name="twitter:image" content={post.frontmatter.image_url} />
      </Helmet>
      <section className="post-hero--container post-item--container">
        <article className="post-hero--content">
          <h3 className='title'>{post.frontmatter.title}</h3>
          <section className="blogItem--labels">
            {
              post.frontmatter.labels.split(',').map((label) => (
                <div className="blogItem--label">
                  <div className="label-img--container">
                    <img src={label_light} alt="Label-icon" />
                  </div>
                  <p>{label}</p>
                </div>
              ))
            }
          </section>
          <p>{post.frontmatter.date}</p>
          <p className='description'>{post.frontmatter.description}</p>
          <div className="image--container">
              <img src={post.frontmatter.image_url} alt="PostImage" />
          </div>
          <div className="footer-blog-container">
              <p>Written by: <strong>{post.frontmatter.author}</strong></p>
          </div>
        </article>
      </section>
      <article
        className="blog-post post-item--content post-item--container"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      {post.frontmatter.kind === 'engineering' && (
        <article className="post-more--container">
          <section className="post-more--content">
            <h2>Extra material regarding the post</h2>
            <p>We provide you with a .pdf version of this post, and you can also access its GitHub repository by clicking the corresponding button.</p>
            <div className="bottons--container">
              <a href={post.frontmatter.pdf_spanish_url} target="_blank">
                <button>Download Spanish</button>
              </a>
              <a href={post.frontmatter.pdf_english_url} target="_blank">
                <button>Download English</button>
              </a>
              <a href={post.frontmatter.repo_url} target="_blank" rel="noopener noreferrer">
                <button className='repository'>Repository</button>
              </a>
            </div>  
          </section>
        </article>
      )}
      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image_url
        author
        type
        labels
        kind
        repo_url
        pdf_spanish_url
        pdf_english_url
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
